

import Routing from './routing/Routing';


function App() {
  

  return (
    <>
      <Routing />
      
    </>
  );
}

export default App;
