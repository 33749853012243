import React, { useEffect, useState } from "react";
import { api } from "../../../urls";
import Footer from "./footer";
import Header from "./header";
import { useTranslation } from "react-i18next";

function Terms_condition() {
  const [count, setcount] = useState(true);
  const [investor, setinvestor] = useState({});

  const { t } = useTranslation("termsOfUse");
  useEffect(() => {
    if (count) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(api + "/api/termofuse", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setinvestor(result.data);
        })
        .catch((error) => console.log("error", error));
      setcount(false);
    }
  }, [count]);
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="contact privacy">
				{investor.title ?						 
            <h2>{t("title", { defaultValue: investor.title })}</h2> :""
				}
            {investor.description ?
            <div
              dangerouslySetInnerHTML={{
                __html: t("description", {
                  defaultValue: investor.description,
                }),
              }}
              className="privacycon"
            /> :"" }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms_condition;
