import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import TranslateComponent from "../../../translate-component";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation("footer");

  const [fmenu, setfmenu] = useState();
  const [cooki, setcooki] = useState(
    localStorage.getItem("cooki")
      ? JSON.parse(localStorage.getItem("cooki"))
      : true
  );
  const [check, setcheck] = useState(useLocation());
  const [isWideScreen, setIsWideScreen] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsWideScreen(window.innerWidth >= 1920);
    };


    checkScreenWidth();


    window.addEventListener('resize', checkScreenWidth);


    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const usesCookieText = t("usesCookie").replace("{@br}", isWideScreen ? "<br>" : "");

  return (
    <>
      <div className="container-fluid dask">
        <div className="footer">
          <div className="head">
            <div className="container">
              <ul>
                {/* <li><TranslateComponent text="Contact" /></li> */}
                <li>{t("header")}</li>
                <li>{t("about")}</li>
                <li>{t("resources")}</li>
                <li>{t("legal")}</li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="footer_menu">
              <ul>
                <li>
                  <Link to="/contact-us">{t("contact")}</Link>
                </li>
                <li>
                  <Link to="/work-with-us">{t("work")}</Link>
                </li>
                <li>
                  <Link to="/career">{t("career")}</Link>
                </li>
                <li>
                  <Link to="/investor-relations">{t("Investor Relation")}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/about-sensCare">{t("About SensCare")}</Link>
                </li>
                <li>
                  <Link to="/faq">{t("FAQ")}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/resources">{t("Resources")}</Link>
                </li>
                <li>
                  <Link to="/safety-center">{t("Safety center")}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/terms-of-use">{t("Terms of use")}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{t("Privacy policy")}</Link>
                </li>
                <li>
                  <Link to="/cookie-policy">{t("Cookies policy")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bottom">
            <ul>
              <li>©{t("SensCare")}</li>
              <li>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/people/SensCare/100066554561840/"
                      target="_blank"
                    >
                      <img
                        src={window.location.origin + "/images/facebook.svg"}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/sens-care/"
                      target="_blank"
                    >
                      <img src={window.location.origin + "/images/indi.svg"} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/mysenscare/"
                      target="_blank"
                    >
                      <img
                        src={window.location.origin + "/images/twiter.svg"}
                      />
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">
                  <img src={window.location.origin + "/images/app_store.svg"} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={window.location.origin + "/images/logo.svg"} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {check.pathname == "*" ? (
        <div className="container-fluid mobilef">
          <div className="footer">
            <div className="container">
              <div className="footer_menu">
                <h4
                  id="fm1"
                  onClick={(a) => {
                    fmenu ? setfmenu() : setfmenu("fm1");
                  }}
                >
                  {t("Contact")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={fmenu == "fm1" ? "show" : "hide"}>
                  <li>
                    <Link to="/contact-us">{t("contact")}</Link>
                  </li>
                  <li>
                    <Link to="/work-with-us">{t("work")}</Link>
                  </li>
                  <li>
                    <Link to="/career">{t("career")}</Link>
                  </li>
                  <li>
                    <Link to="/investor-relations">
                      {t("Investor Relation")}
                    </Link>
                  </li>
                </ul>
                <h4
                  id="fm2"
                  onClick={(a) => {
                    fmenu ? setfmenu() : setfmenu("fm2");
                  }}
                >
                  {t("about")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={fmenu == "fm2" ? "show" : "hide"}>
                  <li>
                    <Link to="/about-sensCare">{t("About SensCare")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{t("FAQ")}</Link>
                  </li>
                </ul>
                <h4
                  id="fm3"
                  onClick={(a) => {
                    fmenu ? setfmenu() : setfmenu("fm3");
                  }}
                >
                  {t("resources")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={fmenu == "fm3" ? "show" : "hide"}>
                  <li>
                    <Link to="/resources">{t("Resources")}</Link>
                  </li>
                  <li>
                    <Link to="/safety-center">{t("Safety center")}</Link>
                  </li>
                </ul>
                <h4
                  id="fm4"
                  onClick={(a) => {
                    fmenu ? setfmenu() : setfmenu("fm4");
                  }}
                >
                  {t("legal")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={fmenu == "fm4" ? "show" : "hide"}>
                  <li>
                    <Link to="/terms-of-use">{t("Terms of use")}</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">{t("Privacy policy")}</Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">{t("Cookies policy")}</Link>
                  </li>
                </ul>
              </div>
              <div className="bottom2 ">
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        src={window.location.origin + "/images/white_logo.svg"}
                      />
                    </Link>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <Link to="#">
                          <img
                            src={window.location.origin + "/images/whitefb.svg"}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            src={window.location.origin + "/images/whitein.svg"}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            src={
                              window.location.origin + "/images/whitetwiter.svg"
                            }
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        src={window.location.origin + "/images/app_store.svg"}
                      />
                    </Link>
                  </li>
                  <li>©{t("SensCare")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className='container-fluid mobilef'>
        <div className='footer'>

          <div className='container'>
            <div className='footer_menu'>
              <h4 id='fm1' onClick={a => {
                fmenu ? setfmenu() :
                  setfmenu('fm1')
              }}>Contact <img src={window.location.origin + '/images/down_arrow.svg'} /></h4>
              <ul className={fmenu == 'fm1' ? "show" : "hide"}>
                <li><Link to='/contact-us'>Contact us </Link></li>
                <li><Link to='/work-with-us'>Work with us</Link></li>
                <li><Link to='/career'>Career</Link></li>
                <li><Link to='/investor-relations'>Investor Relation</Link></li>
              </ul>
              <h4 id='fm2' onClick={a => {
                fmenu ? setfmenu() : setfmenu('fm2')
              }}>About<img src={window.location.origin + '/images/down_arrow.svg'} /></h4>
              <ul className={fmenu == 'fm2' ? "show" : "hide"}>
                <li><Link to='/about-sensCare'>About SensCare</Link></li>
                <li><Link to='/faq'>FAQ</Link></li>
              </ul>
              <h4 id='fm3' onClick={a => {
                fmenu ? setfmenu() : setfmenu('fm3')
              }}>Resources<img src={window.location.origin + '/images/down_arrow.svg'} /></h4>
              <ul className={fmenu == 'fm3' ? "show" : "hide"}>
                <li><Link to='/resources'>Resources for parents</Link></li>
                <li><Link to='/safety-center'>Safety center</Link></li>
              </ul>
              <h4 id='fm4' onClick={a => {
                fmenu ? setfmenu() : setfmenu('fm4')
              }}>Legal <img src={window.location.origin + '/images/down_arrow.svg'} /></h4>
              <ul className={fmenu == 'fm4' ? "show" : "hide"}>
                <li><Link to='/terms-of-use'>Terms of use</Link></li>
                <li><Link to='/privacy-policy'>Privacy policy</Link></li>
                <li><Link to='/cookies-policy'>Cookies policy</Link></li>
              </ul>

            </div>
            <div className='bottom2 '>
              <ul>
                <li>
                  <Link to='/'><img src={window.location.origin + '/images/white_logo.svg'} /></Link>
                </li>
                <li>
                  <ul>
                    <li>
                      <Link to='#'>
                        <img src={window.location.origin + '/images/whitefb.svg'} />
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        <img src={window.location.origin + '/images/whitein.svg'} />
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        <img src={window.location.origin + '/images/whitetwiter.svg'} />
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='#'><img src={window.location.origin + '/images/app_store.svg'} /></Link>
                </li>
                <li>© 2024 SensCare. All Rights Reserved. </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      {cooki ? (
        <div className="cookie_pop">
          <div className="container">
            <img src={window.location.origin + "/images/cooki_icon.svg"} />

            <p>
              <span dangerouslySetInnerHTML={{ __html: usesCookieText }}></span>
              <Link to="/cookie-policy" target="_blank"> <u> {t("learn")} </u>
               
              </Link>
            </p>
            <button
              onClick={(e) => {
                localStorage.setItem("cooki", false);
                setcooki(false);
              }}
            >
              {t("decline")}
            </button>
            <button
              className="accept"
              onClick={(e) => {
                localStorage.setItem("cooki", false);
                setcooki(false);
              }}
            >
              {t("accept")}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Footer;
