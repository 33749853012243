import { FacebookButton, FacebookCount, LinkedInButton } from "react-social";

function Facebook_share(props) {
    let url = props.link;
    return (
        <>
            <div className="social_share">
                {
                    props.icon && props.icon == "yes" ?
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                        :
                        <img src={window.location.origin + "/images/share_icon.svg"} alt="" />
                }
                <ul>
                    <li>
                        <FacebookButton url={url} appId={3384521628485216}>
                            {/* <FacebookCount url={url} /> */}
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="15" fill="#7D2B8B" />
                                <path d="M15.0449 20.9999V15.5262H16.8823L17.1573 13.3929H15.0449V12.0309C15.0449 11.4133 15.2165 10.9924 16.1022 10.9924L17.2318 10.9919V9.08397C17.0363 9.05806 16.3658 9 15.5858 9C13.9571 9 12.8421 9.99409 12.8421 11.8198V13.393H11V15.5262H12.842V21L15.0449 20.9999Z" fill="white" />
                            </svg>
                        </FacebookButton>
                    </li>
                    <li>
                        <LinkedInButton url={url} appId={"860qiqz9pllqgi"}>
                            {/* <FacebookCount url={url} /> */}
                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15.5" cy="15" r="15" fill="#7D2B8B" />
                                <path d="M22.2425 20.8904C21.4824 20.8723 20.7404 20.8723 19.9804 20.8904C19.8175 20.8904 19.7813 20.8542 19.7813 20.6913C19.7813 19.3521 19.7813 17.9949 19.7813 16.6557C19.7813 16.348 19.7632 16.0404 19.6727 15.7508C19.4013 14.8098 18.3154 14.4659 17.5373 15.0993C17.121 15.4251 16.9581 15.8775 16.9581 16.4204C16.9581 17.6872 16.9581 18.954 16.9581 20.2208C16.9581 20.3837 16.9401 20.5465 16.9581 20.7275C16.9762 20.8723 16.9039 20.9085 16.7772 20.8904C15.999 20.8904 15.2389 20.8904 14.4608 20.8904C14.316 20.8904 14.2798 20.8542 14.2798 20.7094C14.2979 19.5693 14.2979 18.4292 14.2979 17.271C14.2979 15.8594 14.2979 14.4479 14.2798 13.0544C14.2798 12.8915 14.316 12.8553 14.4608 12.8553C15.2389 12.8553 15.999 12.8553 16.7772 12.8553C16.922 12.8553 16.9581 12.8915 16.9581 13.0363C16.9581 13.3258 16.9581 13.6154 16.9581 13.9592C17.0124 13.9049 17.0305 13.8868 17.0486 13.8687C17.7544 12.8372 18.7498 12.5477 19.9261 12.7286C21.2833 12.9458 22.152 13.8868 22.3692 15.3346C22.4235 15.6784 22.4416 16.0223 22.4416 16.3661C22.4416 17.8139 22.4416 19.2435 22.4416 20.6913C22.4416 20.8361 22.4054 20.8904 22.2425 20.8904Z" fill="white" />
                                <path d="M12.8321 16.9824C12.8321 18.2492 12.8321 19.516 12.8321 20.7827C12.8321 20.9456 12.7959 20.9999 12.6331 20.9999C11.873 20.9818 11.1129 20.9999 10.3528 20.9999C10.2081 20.9999 10.1719 20.9637 10.1719 20.8189C10.1719 18.2673 10.1719 15.6975 10.1719 13.1458C10.1719 13.0191 10.2081 12.9648 10.3528 12.9648C11.131 12.9648 11.9092 12.9648 12.6874 12.9648C12.8502 12.9648 12.8683 13.0191 12.8683 13.1639C12.8321 14.4307 12.8321 15.6975 12.8321 16.9824Z" fill="white" />
                                <path d="M12.9585 10.7562C12.7775 11.4801 12.0355 11.8963 11.185 11.7515C10.2801 11.6067 9.77338 10.72 10.0991 9.85129C10.3163 9.30837 10.8411 8.98262 11.5107 9.00072C12.5241 8.98262 13.1937 9.797 12.9585 10.7562Z" fill="white" />
                            </svg>
                        </LinkedInButton>
                    </li>
                </ul>

            </div>
        </>
    )
}
export default Facebook_share;