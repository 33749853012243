import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { api } from "../../urls";
import Footer from "./common/footer";
import Header from "./common/header";
import { country } from "./common/country";
import { useTranslation } from "react-i18next";
const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
function Contact() {
  const { t } = useTranslation("contact-us");
  const [show, setShow] = useState(false);
  const [country_number, setcountry_number] = useState("");
  const [button, setbutton] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const handleShow = () => setShow(true);
  const [refresh, setrefresh] = useState("");
  const [test, settest] = useState([
    {
      name: "email",
    },
    {
      name: "first_name",
    },
    {
      name: "last_name",
    },
    {
      name: "phone",
    },
    {
      name: "gender",
    },
    {
      name: "message",
    },
  ]);
  const [form_logins, setform_logins] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    gender: "General",
    message: "",
  });
  const [error, seterror] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    gender: "",
    message: "",
  });
  const logins_field_test = (name) => {
    switch (name) {
      case "email":
        error.email = form_logins.email == "" ? `${t("required")}` : "";
        break;
      case "first_name":
        error.first_name =
          form_logins.first_name != "" ? "" : `${t("required")}`;
        break;
      case "last_name":
        error.last_name = form_logins.last_name != "" ? "" : `${t("required")}`;
        break;
      case "phone":
        error.phone = form_logins.phone != "" ? "" : `${t("required")}`;
        break;
      case "gender":
        error.gender = form_logins.gender != "" ? "" : `${t("required")}`;
        break;
      case "message":
        error.message = form_logins.message != "" ? "" : `${t("required")}`;
        break;
      default:
        break;
    }
    seterror(error);
    setTimeout(() => {
      setrefresh("njjijiinibiibi");
    }, 1000);
  };
  const logins_field = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        error.email = emailRegex.test(value) ? "" : `${t("emailNotValid")}`;

        break;
      case "first_name":
        error.first_name = value.length < 2 ? `${t("minRequired")}` : "";
        break;
      case "last_name":
        error.last_name = value.length < 2 ? `${t("minRequired")}` : "";
        break;
      case "phone":
        error.phone = value.length < 8 ? `${t("required")}` : "";
        break;
      case "gender":
        error.gender = value.length < 2 ? `${t("required")}` : "";
        break;
      case "message":
        error.message = value.length < 2 ? `${t("required")}` : "";
        error.message = value.length > 301 ? `${t("maxRequired")}` : "";
        break;
      default:
        break;
    }
    setform_logins({ ...form_logins, [name]: value });
    seterror(error);
  };

  const contact_us = () => {
    setbutton(true);
    if (
      (error.email == "") &
      (error.first_name == "") &
      (error.last_name == "") &
      (error.phone == "") &
      (error.gender == "") &
      (error.message == "") &
      (form_logins.email != "") &
      (form_logins.first_name != "") &
      (form_logins.last_name != "") &
      (form_logins.phone != "") &
      (form_logins.gender != "") &
      (form_logins.message != "")
    ) {
      var formdata = new FormData();
      formdata.append("first_name", form_logins.first_name);
      formdata.append("last_name", form_logins.last_name);
      formdata.append("email", form_logins.email);
      formdata.append("phone", country_number + form_logins.phone);
      formdata.append("related_question", form_logins.gender);
      formdata.append("message", form_logins.message);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(api + "/api/contactus", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          result.success ? handleShow() : handleClose();
          setbutton(false);
        })
        .catch((error) => console.log("error", error));
    } else {
      test.map((e) => {
        logins_field_test(e.name);
      });
      setbutton(false);
    }
  };


  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="contact">
            <h2>{t("contactUs")}</h2>
            <p>{t("create")}</p>
            <h3>{t("greeting")}</h3>
            <div className="form border">
              <form className="">
                <div className="form_group">
                  <label>
                    {t("first")}<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    name="first_name"
                    onChange={(e) => logins_field(e)}
                    className={error.first_name == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{error.first_name}</span>
                </div>
                <div className="form_group">
                  <label>
                    {t("last")}<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    name="last_name"
                    onChange={(e) => logins_field(e)}
                    className={error.last_name === "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{error.last_name}</span>
                </div>
                <div className="form_group">
                  <label>
                    {t("email")}<span>*</span>
                  </label>
                  <input
                    type="email"
                    placeholder={t("type")}
                    name="email"
                    onChange={(e) => logins_field(e)}
                    className={error.email == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{error.email}</span>
                </div>
                <div className="form_group part2">
                  <label>{t("telephone")}</label>
                  <div className="citydetail small">
                    <select onChange={(e) => setcountry_number(e.target.value)}>
                      <option selected></option>
                      {country.data.map((e) => {
                        return <option value={e.dial_code}>{e.country}</option>;
                      })}
                    </select>
                  </div>
                  <div className="citydetail">
                    <input
                      type="number"
                      placeholder={t("type")}
                      name="phone"
                      onChange={(e) => logins_field(e)}
                      className={error.phone == "" ? "" : "bordererror"}
                      style={{ paddingLeft: country_number ? '32px' : '20px' }}
                      />
                    <div className="errorfield">{error.phone}</div>
                    <span>{country_number}</span>
                  </div>
                </div>
                <div className="form_group2">
                  <label>{t("ask")}</label>
                  <select
                    name="gender"
                    onChange={(e) => logins_field(e)}
                    className={error.gender == "" ? "" : "bordererror"}
                  >
                        <option>{t("general")}</option>
                    <option>{t("parents")}</option>
                    <option>{t("providers")}</option>
                    <option>{t("tech")}</option>
                
                  </select>
                  <span className="errorfield">{error.gender}</span>
                </div>
                <div className="form_group full">
                  <label>
                    {t("message")}<span>*</span>
                  </label>
                  <textarea
                    rows={2}
                    placeholder={t("type")}
                    maxlength="300"
                    name="message"
                    onChange={(e) => logins_field(e)}
                    className={error.message == "" ? "" : "bordererror"}
                  ></textarea>
                  <div className="errorfield">{error.message}</div>
                  <span>{t("number")}</span>
                </div>
              </form>
              <button onClick={contact_us} disabled={button}>
                {t("submit")}
              </button>
            </div>
            <div className="follow">
              <h3>{t("follow")}</h3>
              <ul>
                <li>
                  <Link to="#">
                    <img src="./images/facebookp.svg" />
                  </Link>
                  <Link to="#">
                    <img src="./images/indip.svg" />
                  </Link>
                  <Link to="#">
                    <img src="./images/twiterp.svg" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="promocode_content younger contactpop">
            <Link to="" onClick={handleClose}>
              +{" "}
            </Link>
            <h5>{t("Thank you for contacting us.")}</h5>
            <p>
              {t("SensCare has received your message.")}
              <br />
              {t("Please allow us up to 24hrs to get back to you.")}
            </p>
            <button onClick={handleClose}>{t("OK")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}

export default Contact;
