import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";


function SelectBoxCustom(props) {
  const { t, i18n } = useTranslation();

  const [items, setItems] = React.useState(props.items || []);
  console.log(items, 'items')
  const [showItems, setShowItems] = React.useState(false);
  const [country, setCountry] = React.useState('');
  const [country1, setCountry1] = React.useState(true);
  //   const [selectedItem, setSelectedItem] = React.useState(
  //     localStorage.getItem("language") != null
  //       ? localStorage.getItem("language") === "sr"
  //         ? props.items[1]
  //         : props.items[0]
  //       : props.items && props.items[0]
  //   );
  const api = 'https://adminsenscare.sdsstaging.co.uk'
  const profile_data = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result,'result')
        setCountry(result.data.country)
        // console.log(country,'country')
      })
      .catch((error) => console.log("error", error));
  };
  const [selectedItem, setSelectedItem] = React.useState(() => {
    const storedLang = localStorage.getItem("language");
    return storedLang
      ? items.find((item) => item.lang === storedLang) || items[0]
      : items[0];
  });

  useEffect(() => {
    profile_data()
  },);

  const dropDown = () => {
    setShowItems(!showItems);
  };

  const selectItem = (item) => {

    setSelectedItem(item);
    setShowItems(false);
    // window.location.reload();
    localStorage.setItem("language", item.lang);
    i18n.changeLanguage(item.lang);
  };



  useEffect(() => {
    console.log(country, 'country')
    console.log(window.location.pathname)
    if (country != '' && country1 && window.location.pathname === '/search-providers' || window.location.pathname === '/search-parents') {
      const userCountry = country ? country : '';
      console.log(userCountry, 'usercountry')


      let initialSelectedItem;

      if (userCountry === "Serbia") {

        initialSelectedItem = items[1];
        console.log(initialSelectedItem, 'intailselecteditem')
        setCountry1(false)
      } else {
        initialSelectedItem = items[0]
        setCountry1(false)
      }

      setSelectedItem(initialSelectedItem);
      localStorage.setItem("language", initialSelectedItem.lang);
      i18n.changeLanguage(initialSelectedItem.lang);
    }


  }, [country, items]);



  return (
    <div className="select-box--box">
      <div className="select-box--container">
        <div className="select-box--selected-item" onClick={dropDown}>
          {selectedItem.value} <img src={selectedItem.icon} />
        </div>
        <div
          style={{ display: showItems ? "block" : "none" }}
          className={"select-box--items"}
        >
          <div className="drop">
            {items.map((item) => (
              <div
                key={item.id}
                onClick={() => selectItem(item)}
                className={selectedItem === item ? "selected" : ""}
              >
                {item.value} <img src={item.icon} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectBoxCustom;
