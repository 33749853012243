import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { api } from "../../urls";
import { country } from "./common/country";
import Footer from "./common/footer";
import Header from "./common/header";
import { useTranslation } from "react-i18next";
const emailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
function Work_us() {
  const { t } = useTranslation("workWithUs");

  const [show, setShow] = useState(false);
  const [count, setcount] = useState(true);
  const [investor, setinvestor] = useState({});
  const [country_number, setcountry_number] = useState("");
  const [button, setbutton] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const handleShow = () => setShow(true);
  const [refresh, setrefresh] = useState("");
  const [test, settest] = useState([
    {
      name: "email",
    },
    {
      name: "first_name",
    },
    {
      name: "last_name",
    },
    {
      name: "phone",
    },
    {
      name: "message",
    },
  ]);
  const [form_logins, setform_logins] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    message: "",
  });
  console.log(form_logins,'formlogins phone')
  const [error, seterror] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    message: "",
  });

  const logins_field_test = (name) => {
    switch (name) {
      case "email":
        error.email = form_logins.email == "" ? "required" : "";
        break;
      case "first_name":
        error.first_name = form_logins.first_name != "" ? "" : "required";
        break;
      case "last_name":
        error.last_name = form_logins.last_name != "" ? "" : "required";
        break;
      case "phone":
        error.phone = form_logins.phone != "" ? "" : "required";
        break;
      case "message":
        error.message = form_logins.message != "" ? "" : "required";
        break;
      default:
        break;
    }
    seterror(error);
    setTimeout(() => {
      setrefresh("njjijiinibiibi");
    }, 1000);
  };
  const logins_field = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        error.email = emailRegex.test(value) ? "" : t("Email not valid.");

        break;
      case "first_name":
        error.first_name =
          value.length < 2 ? t("Minimum 2 characters required") : "";
        break;
      case "last_name":
        error.last_name =
          value.length < 2 ? t("Minimum 2 characters required") : "";
        break;
        case 'phone':
              
  
      error.phone =value.length < 8?
          t("required"):''

  
      break;

   
      case "message":
        error.message = value.length < 2 ? t("required") : "";
        error.message =
          value.length > 301 ? t("Maximum 300 characters valid") : "";
        break;
      default:
        break;
    }
    setform_logins({ ...form_logins, [name]: value });
    seterror(error);
  };

  const contact_us = () => {
    setbutton(true);
    console.log(error.phone,'phone error')
    console.log(form_logins.phone.length,'phon eno length')
    if (
      (error.email == "") &
      (error.first_name == "") &
      (error.last_name == "") &
      (error.phone == "") &
      (error.message == "") &
      (form_logins.email != "") &
      (form_logins.first_name != "") &
      (form_logins.last_name != "") &
      (form_logins.phone != "") &
      (form_logins.message != "")
    ) {
      var formdata = new FormData();
      formdata.append("first_name", form_logins.first_name);
      formdata.append("last_name", form_logins.last_name);
      formdata.append("email", form_logins.email);
      formdata.append("phone", contact_code.code + form_logins.phone);
      // formdata.append("related_question", form_logins.gender);
      formdata.append("message", form_logins.message);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(api + "/api/workwithusenquiry", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          result.success ? handleShow() : handleClose();
          setbutton(false);
        })
        .catch((error) => console.log("error", error));
        // alert('success')
    } else {
      test.map((e) => {
        logins_field_test(e.name);
      });
      setbutton(false);
      // alert('failed')
    }
  };
  useEffect(() => {
    // if (count) {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(api + "/api/workwithus", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setinvestor(result.data);
      })

      .catch((error) => console.log("error", error));
    setcount(false);
    // }
  }, []);

  const codeselect = () => {
    if (setcode) {
        setsetcode(false)
    } else {
        setsetcode(true)
    }
}

const [contact_code, setcontact_code] = useState({
    code: "",
    flag: ""
})

const [setcode, setsetcode] = useState(true)
console.log(contact_code.code,'countrynumber')
  return (
    <>
      <Header />
      <div className="container-fluid position-relative">
        <div className="container ">
          <div className="contact">
            <h2>{t("title", { defaultValue: investor.title })}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: t("content", { defaultValue: investor.description }),
              }}
              className="workus"
            />
            <h3>{t("Send us message")}</h3>
            <div className="form border">
              <form className="">
                <div className="form_group">
                  <label>
                    {t("First name")}<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Type here")}
                    name="first_name"
                    onChange={(e) => logins_field(e)}
                    className={error.first_name == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{error.first_name}</span>
                </div>
                <div className="form_group">
                  <label>
                    {t("Last name")}<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Type here")}
                    name="last_name"
                    onChange={(e) => logins_field(e)}
                    className={error.last_name == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{error.last_name}</span>
                </div>
                <div className="form_group">
                  <label>
                    {t("Email address")}<span>*</span>
                  </label>
                  <input
                    type="email"
                    placeholder={t("Type here")}
                    name="email"
                    onChange={(e) => logins_field(e)}
                    className={error.email == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{error.email}</span>
                </div>
                <div className="form_group part2">
                 
                  <label>{t("Telephone number")}</label>
                                    <div className= "country_flag" onClick={e => codeselect()}><img src={contact_code.flag} /> {contact_code.code}</div>
                                    <ul style={setcode ? { display: "none" } : {}}>
                                        {
                                            country.data.map((e) => {
                                                return (
                                                    <li onClick={a => {
                                                        codeselect()
                                                        setcontact_code({ "flag": e.flag, "code": e.dial_code })
                                                    }}><img src={e.flag} /> {e.country + " " + " " + e.dial_code}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                    {/* {error.phone ?
                                        <span className='errorfield'>{error.phone} </span> : ""
                                    } */}
                                      <span>{country_number}</span>
                  <div className="citydetail">
                    <input
                      type="number"
                      placeholder={t("Type here")}
                      name="phone"
                      onChange={(e) => logins_field(e)}
                      className={error.phone == "" ? "" : "bordererror"}
                      style={{ paddingLeft: country_number ? '32px' : '20px',borderRadius:'0px 40px 40px 0px' }}
                    />
                    <div className="errorfield">{error.phone}</div>
                 
                  </div>
                </div>
                <div className="form_group full">
                  <label>
                    {t("Your message")}<span>*</span>
                  </label>
                  <textarea
                    rows={2}
                    placeholder={t("Type here")}
                    maxlength="300"
                    name="message"
                    onChange={(e) => logins_field(e)}
                    className={error.message == "" ? "" : "bordererror"}
                  ></textarea>
                  <div className="errorfield">{error.message}</div>
                  <span>{t("Number of characters 300")}</span>
                </div>
              </form>
              <button onClick={contact_us} disabled={button}>
                {t("Submit")}
              </button>
            </div>
            <div className="follow">
              <h3>{t("Follow us on social media")}</h3>
              <ul>
                <li>
                  <Link to="#">
                    <img src="./images/facebookp.svg" />
                  </Link>
                  <Link to="#">
                    <img src="./images/indip.svg" />
                  </Link>
                  <Link to="#">
                    <img src="./images/twiterp.svg" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="promocode_content younger contactpop">
            <Link to="" onClick={handleClose}>
              +{" "}
            </Link>
            <h5>{t("Thank you for contacting us.")}</h5>
            <p>
              {t("SensCare has received your message.")}
              <br />
              {t("Please allow us up to 24hrs to get back to you.")}
            </p>
            <button onClick={handleClose}>{t("OK")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}

export default Work_us;
